import * as webstore from './FlextimeWebstore.js'
import moment from 'moment'
import _ from 'lodash';

import { delay, eventChannel } from 'redux-saga'
import { fork, put, all, takeEvery, call, select, take, cancel, cancelled } from 'redux-saga/effects'
import authentication from '../Authentication/AuthenticationSlice';
import flextime from '../Flextime/FlextimeSlice';

function* getMonth(date, userId){   
    const year = date.year();
    const month = date.month();

    let days = yield webstore.GetMonth(userId, year, month);

    const updatedMonth = { days: days || {}, year, month }
    yield put(flextime.actions.monthFetched(updatedMonth));
}

function* loadCalander({ payload }) {
    const lastMonth = moment().add(-1, 'months');
    const thisMonth = moment();
    const nextMonth = moment().add(1, 'months');

    yield all([
        call(getMonth, lastMonth, payload.userId),
        call(getMonth, thisMonth, payload.userId),
        call(getMonth, nextMonth, payload.userId)
    ]);

    yield put(flextime.actions.loaded());

    const watchWeekTask = yield fork(watchWeek, payload.userId);

    yield take(authentication.actions.signedOut);
    yield cancel(watchWeekTask);
    yield put(flextime.actions.signedOut());
}

function* doUpdateDay ({payload:{ userId, day:{day}, inTime, outTime }}){
    yield webstore.SetInTime(userId, day, inTime);
    yield webstore.SetOutTime(userId, day, outTime);
}

function* doClockIn ({ payload }){
    const now = moment();
    yield webstore.SetInTime(payload.userId, now, now);
}

function* doClockOut ({ payload }){
    const now = moment();
    yield webstore.SetOutTime(payload.userId, now, now);
}

function* incrementToday() {
    while(true){
        const today = yield select(flextime.selectors.getToday);
        const nextMidnight = moment(today.day).startOf('day').add(1, 'days');
        const waitTime = nextMidnight.diff(moment());
        yield delay(waitTime);
        yield put(flextime.actions.updateToday(nextMidnight));
    }
}

function* watchDay(userId, day) {
    const watchEvents = _.partial(webstore.WatchDayChanges, userId, day);
    const channel = eventChannel(watchEvents);

    try {
        while(true) {
            const changeEvent = yield take(channel);
            const payload = { 
                ...changeEvent.value,
                day:moment(day).startOf('day')
            };
            yield put(flextime.actions.dayUpdated(payload));
        }
    }
    finally {
        if (yield cancelled()) {
            channel.close();
        }
    }
}

function* watchWeek(userId) {    
    while(true){
        const thisWeek = yield select(flextime.selectors.getThisWeek);

        let watches = [];

        try {
            for (let entry of thisWeek) {
                const task = yield fork(watchDay, userId, entry.day);
                watches.push(task);
            }

            yield take(flextime.actions.updateToday);

        } finally {
            for (let task of watches) {
                yield cancel(task);
            }
        }
    }
}

function* watch() {
    yield all([
        takeEvery(authentication.actions.signedIn, loadCalander),
        takeEvery(flextime.actions.clockIn, doClockIn),
        takeEvery(flextime.actions.clockOut, doClockOut),
        takeEvery(flextime.actions.updateDay, doUpdateDay)
    ]);
}

export default function* (){
    yield fork(watch);
    yield fork(incrementToday);
}