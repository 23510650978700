import { createSlice, createSelector } from 'redux-starter-kit';

const name = 'authentication';

let authentication = createSlice({
    slice: name,
    initialState: {
        hasAuthState: false,
        user: null,
        isSignedIn: false,
        isSigningIn: false,
    },
    reducers: {
        signInWithGoogle: (state) => { state.isSigningIn = true },
        signOut: null,
        signedIn: (state, { payload }) => {
            state.hasAuthState = true;
            state.user = payload;
            state.isSignedIn = true;
            state.isSigningIn = false;
        },
        signedOut: (state) => {
            state.hasAuthState = true;
            state.isSignedIn = false;
            state.user = null;
        }
    }
})

authentication.selectors = {
    ...authentication.selectors,
    getUserId: createSelector(
        [authentication.selectors.getAuthentication],
        auth => {
            return auth.user.userId;
        })
}

export default authentication;