import * as webstore from './UserSettingsWebstore.js'
import { put, all, takeEvery, take, cancel } from 'redux-saga/effects'
import authentication from '../Authentication/AuthenticationSlice.js';
import userSettings from './UserSettingsSlice';
import { eventChannel } from 'redux-saga';
import { curry } from 'lodash';

function* watchUserSettings({ payload }){
    function* handleUserSettings(settings){
        yield put(userSettings.actions.userSettingsUpdated(settings.value));
    }

    const userId = payload.userId;
    const channel = eventChannel(curry(webstore.watchUserSettings)(userId));

    const watchSettingsTask = yield takeEvery(channel, handleUserSettings);
    yield take(authentication.actions.signedOut);
    yield cancel(watchSettingsTask);
    yield put(userSettings.actions.signedOut());
    channel.close();
}

export default function* () {
    yield all([
        takeEvery(authentication.actions.signedIn, watchUserSettings)
    ]);
}