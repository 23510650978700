import React from 'react';
import Paper from '../../containers/Paper';
import PunchCard from './PunchCard';
import ShowWeek from './ShowWeek';
import HoursRemaining from './/TodaysStats';
import { connect } from 'react-redux';
import flextime from '../FlextimeSlice'
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    progress: {
        align: 'center',
        margin: theme.spacing(2),
    },
    loadingFlex: {
        display: 'flex',
        justifyContent: 'center'
    }
  });

function Layout (props) 
{
    const { classes } = props;
    const onLoaded  =
        <>
            <PunchCard/>
            <HoursRemaining/>
            <ShowWeek/>
        </>
    const onLoading = 
        <div className={classes.loadingFlex}>
            <CircularProgress className={classes.progress}/>
        </div>

    return <Paper title='Punch Card'>
            {props.isLoaded ? onLoaded : onLoading}
        </Paper>
}

const mapStateToProps = (state) => {
     return { isLoaded: flextime.selectors.getIsLoaded(state) }
}

const styled = withStyles(styles)(Layout);
export default connect(mapStateToProps)(styled);