import moment from "moment";

function asHours({hours, minutes}) {
    return hours + (minutes / 60);
}

export function CanEdit(day){
    const now = moment();
    return day.day < now;
}

export function parseTime(time){
    if(time){
        const match = time.match('(\\d\\d):(\\d\\d)');
        const res = {
            hours: parseInt(match[1], 10),
            minutes: parseInt(match[2], 10)
        }

        return res;
    }
}

export function timeAsMoment(day, string){
    const time = parseTime(string);
    return moment(day.day).hour(time.hours).minute(time.minutes);
}
export function startAsMoment(day){
    if(!day.in){ 
        return null;
    }
    return timeAsMoment(day, day.in);
}
export function endAsMoment(day){
    if(!day.out) {
        return null;
    }
    return timeAsMoment(day, day.out);
}

export function getHoursWorked(day){
    const inTime = day.in && asHours(parseTime(day.in));
    const outTime = day.out && asHours(parseTime(day.out));
    return (outTime - inTime);
}

export function getRemainingHoursForWeek(week, target){
    const hoursWorked = week
        .filter(p => p)
        .map(getHoursWorked)
        .reduce((accumulator, currentValue) => 
        {
            return accumulator + (currentValue || 0)
        }, 0);
 
    return target - hoursWorked;
}

export default class Day {
    constructor(date, inTime, outTime){
        this.day = date;
        this.in = inTime;
        this.out = outTime;
    }
}