import { get, set } from 'lodash';

export default function (name, isEnabled) {
    const path = ['__GUSDOR_LOGGING__', name];

    if(!get(window, path)) {
        set(window, path, isEnabled);
    }

    function logTo(func){
        return function(){
            if (get(window, path)) {
                func.apply(undefined, [name, ":", ...arguments]);
            }
        }
    }

    const log = logTo(console.log);
    log.warn = logTo(console.warn);
    log.error = logTo(console.error);
    log.info = logTo(console.info);

    return log;
}