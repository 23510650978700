import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';

import counter from '../Counter/CounterSlice.js';
import counterSaga from '../Counter/CounterSaga';

import flextime from '../Flextime/FlextimeSlice';
import flextimeSaga from '../Flextime/FlextimeSaga';

import authentication from '../Authentication/AuthenticationSlice';
import authenticationSaga from '../Authentication/AuthenticationSaga';

import userSettings from '../UserSettings/UserSettingsSlice';
import userSettingsSaga from '../UserSettings/UserSettingsSaga';

const reducer = combineReducers({
    [counter.slice]: counter.reducer,
    [flextime.slice]: flextime.reducer,
    [authentication.slice]: authentication.reducer,
    [userSettings.slice]: userSettings.reducer
    });
  
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  
export default createStore(
  reducer, 
  {}, 
  composeEnhancers(applyMiddleware(sagaMiddleware)));
  
  const sagas = function*(){
    yield fork(flextimeSaga); 
    yield fork(counterSaga); 
    yield fork(authenticationSaga); 
    yield fork(userSettingsSaga); 
  }

  sagaMiddleware.run(sagas);