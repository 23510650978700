import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import authentication from '../AuthenticationSlice.js';
import AuthenticationSwitch from './AuthenticationSwitch.js';
const { getAuthentication } = authentication.selectors;

function LoginPortal({signInWithGoogle, user, hasAuthState, signOut}){
    const loginButton = () =>
        <Button color='inherit' onClick={() => signInWithGoogle()}>Login with Google</Button>;
    
    const displayName = user? user.displayName : null;
    const welcomeMessage = () => <>
        <Typography color='inherit'>Hi, {displayName}</Typography>
        <Button color='inherit' onClick={signOut}>Sign Out</Button>
    </>;

    return hasAuthState 
        && <AuthenticationSwitch
            whenNotAuthenticated={loginButton()}
            whenAuthenticated={welcomeMessage()}/>;
}

LoginPortal.propTypes = {
    signInWithGoogle: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
    hasAuthState: PropTypes.bool.isRequired,
    user: PropTypes.shape({
        displayName: PropTypes.string.isRequired,
    })
}

export default connect(
    (state) => ({ 
        hasAuthState: getAuthentication(state).hasAuthState,
        isSignedIn: getAuthentication(state).isSignedIn,
        user: getAuthentication(state).user
     }),
    (dispatch) => ({ 
        signInWithGoogle: () => dispatch(authentication.actions.signInWithGoogle() ),
        signOut: () => dispatch(authentication.actions.signOut())
    }))(LoginPortal);
