import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import flextime from '../FlextimeSlice.js'
import PropTypes from 'prop-types';
import authentication from '../../Authentication/AuthenticationSlice.js';
import { get } from 'lodash'

function PunchCard(props){
    var hasIn = Boolean(get(props, 'today.in'));
    var hasOut = Boolean(get(props, 'today.out'));

    const disableClockIn = Boolean(props.isLoading || hasIn);
    const disableClockOut = Boolean(props.isLoading || (!hasIn || hasOut));
    return <div>
        <Button disabled={disableClockIn} onClick={() => props.clockIn(props.userId)}>Clock In</Button>
        <Button disabled={disableClockOut} onClick={() => props.clockOut(props.userId)}>Clock Out</Button>
    </div>;
}

PunchCard.propTypes = {
    clockIn: PropTypes.func,
    clockOut: PropTypes.func
}

function dispatchToProps(dispatch){
    return { 
        clockIn: userId => dispatch(flextime.actions.clockIn({ userId })),
        clockOut: userId => dispatch(flextime.actions.clockOut({ userId }))
    };
}

function mapStateToProps(state){
    return {
        userId: authentication.selectors.getUserId(state),
        today: flextime.selectors.getToday(state),
        isLoading: flextime.selectors.getFlextime(state).isLoading
    };
}

export default connect(mapStateToProps, dispatchToProps)(PunchCard);

