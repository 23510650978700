import firebase from 'firebase/app';
import Logger from '../Logger';

const log = Logger("Webstore", false);

export async function GetValueFromPath(path) {
    log(`Reading '${path}'`);
    const snapshot = await firebase.database().ref(path).once('value');
    const val = snapshot.val();
    log(`Value at '${path}' is`, val);
    return val;
}

export async function UpdatePath(path, value) {
    log(`Updating '${path}' with `, value);
    let updates = { [path]: value };
    return firebase.database().ref().update( updates );
}

export async function Update(update) {
    log(`Updating with `, update);
    return firebase.database().ref().update( update );
}

export function WatchPath(path, callback) {
    log('Listening to ', path);
    const reference = firebase
        .database()
        .ref(path);
    reference.on('value', snapshot => { 
            try {
                log("Snapshot received from ", path, snapshot.val());
                callback({ value: snapshot.val() });
            } catch (err){
                log("Error occurred observing snapshot.", err);
            }
        });

    return () => { 
        log('Unlistening from ', path);
        reference.off('value');
        log("Unlistened from", path);
    };
}