import * as webstore from '../store/webstore.js';

const ROOT_PATH = 'userdata';
const TIME_FORMAT = 'HH:mm';


function getCalendarPath(userId){
    return ROOT_PATH + `/${userId}/calendar`;
}

function GetDayPath(userId, day) {
    ValidationUserId(userId);
    return getCalendarPath(userId) + `/${day.year()}/${day.month()}/${day.date()}`;
}

function ValidationUserId(userId){
    if(!userId){
        throw new Error(`User ID must be valid. Current value is ${userId}`);
    }
}

export function GetMonth(userId, year, month){
    return webstore.GetValueFromPath(getCalendarPath(userId) + `/${year}/${month}`);
}

export function WatchDayChanges(userId, day, callback) {
    return webstore.WatchPath(GetDayPath(userId, day), callback);
}

export function SetTimes(userId, day, inTime, outTime){
    ValidationUserId(userId);
    const payload = {
        in: inTime.format(TIME_FORMAT),
        out: outTime.format(TIME_FORMAT)
    }
    return webstore.UpdatePath(`${GetDayPath(userId, day)}`, payload);
}

export function SetOutTime(userId, day, value){
    ValidationUserId(userId);
    return webstore.UpdatePath(`${GetDayPath(userId, day)}/out`, value && value.format(TIME_FORMAT));
}

export function SetInTime(userId, day, value){   
    ValidationUserId(userId);
    return webstore.UpdatePath(`${GetDayPath(userId, day)}/in`, value && value.format(TIME_FORMAT));
}