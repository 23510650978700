import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import authentication from '../AuthenticationSlice.js'; 
const { getAuthentication } = authentication.selectors;

function AuthenticationSwitch(props){
    if(props.isSignedIn){
        return props.whenAuthenticated || null;
    } else {
        return props.whenNotAuthenticated || null;
    }
}

AuthenticationSwitch.propTypes = {
    isSignedIn: PropTypes.bool,
    whenAuthenticated: PropTypes.node,
    whenNotAuthenticated: PropTypes.node
}

export default connect(
    (state) => ({ isSignedIn: getAuthentication(state).isSignedIn }))(AuthenticationSwitch);