import React from 'react';
import { KeyboardTimePicker } from "@material-ui/pickers";
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { 
    Table, 
    TableCell, 
    TableHead, 
    TableRow, 
    TableBody, 
    Button, 
    IconButton, 
    Dialog,
    DialogTitle,
    DialogContent, 
    DialogActions } from '@material-ui/core';

import PropTypes from 'prop-types';
import authentication from '../../Authentication/AuthenticationSlice.js';
import flextime from '../FlextimeSlice';
import { getHoursWorked, startAsMoment, endAsMoment, CanEdit } from '../Domain/Day';

const useStyles = makeStyles(theme => ({
    formControl: {
        marginTop: theme.spacing(1),
        minWidth: 120,
    },
    button: {
        margin: theme.spacing(1),
    },
}));

function ShowWeek({week, userId, updateDay}){
    const [editingDay, setEditingDay] = React.useState(null);    

    const handleCancelEditDayDialog = () =>
    {
        setEditingDay(null);
    }

    const handleCommitEditDayDialog = () =>
    {
        setEditingDay(null);
        updateDay(userId, editingDay.day, editingDay.in, editingDay.out);
    }

    const setEditDayIn = time => {
        setEditingDay({ ...editingDay, in: time});  
    };

    const setEditDayOut = time => {
        setEditingDay({ ...editingDay, out: time});  
    };

    const classes = useStyles();

    return week && 
    <>
        <Dialog open={editingDay != null}>
            <DialogTitle>Edit day</DialogTitle>
            <DialogContent>
                <KeyboardTimePicker 
                    className={classes.formControl} 
                    ampm={false}
                    label="Start" 
                    value={editingDay && editingDay.in} onChange={setEditDayIn}/>
                    <br/>
                <KeyboardTimePicker 
                    className={classes.formControl}
                    ampm={false}
                    label="End" 
                    value={editingDay && editingDay.out} onChange={setEditDayOut}/>
                <br/>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelEditDayDialog}>Cancel</Button>
                <Button onClick={handleCommitEditDayDialog} color="primary">OK</Button>
            </DialogActions>
        </Dialog>
        <Table padding='none'>
            <TableHead>
                <TableRow>
                    <TableCell>Day</TableCell>
                    <TableCell>In</TableCell>
                    <TableCell>Out</TableCell>
                    <TableCell>Hours</TableCell>
                    <TableCell> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {week.filter(p => p).map((p, _) => {
                    const handleClickEditDay = () => {
                        setEditingDay({ day:p, in:startAsMoment(p), out: endAsMoment(p)});
                    }

                    return <TableRow key={p.day.format('YYYY MMM DD')}>
                        <TableCell>{p.day.format('ddd Do MMM')}</TableCell>
                        <TableCell>{p.in}</TableCell>
                        <TableCell>{p.out}</TableCell>
                        <TableCell>{p.in && p.out && getHoursWorked(p).toFixed(2)}</TableCell>
                        <TableCell>
                            <IconButton className={classes.button} style={{visibility: CanEdit(p) ? 'visible' : 'hidden' }} onClick={handleClickEditDay}>
                                <EditIcon color="action"/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                })}
            </TableBody>
        </Table>
    </>
}

ShowWeek.propTypes = {
    userId: PropTypes.string.isRequired,
    updateDay: PropTypes.func.isRequired,
    week: PropTypes.arrayOf(
        PropTypes.shape({
            in: PropTypes.string,
            out: PropTypes.string
        }).isRequired
    ).isRequired
}

function dispatchToProps(dispatch){
    return { 
        updateDay: (userId, day, inTime, outTime) => 
            dispatch(flextime.actions.updateDay({ userId, day, inTime, outTime }))
    };
}

function mapStateToProps(state) {
    return {
        userId: authentication.selectors.getAuthentication(state).user.userId,
        week: flextime.selectors.getThisWeek(state)
    };
}

export default connect(mapStateToProps, dispatchToProps)(ShowWeek);

