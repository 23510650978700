import * as webstore from './CounterWebstore.js'

import { eventChannel } from 'redux-saga';
import { put, takeLatest, fork, all, takeEvery } from 'redux-saga/effects'
import counter from './CounterSlice';

function* doIncrement(action) {
    yield webstore.IncrementCountBy(action.payload);
}

function* onUpdateReceived({ value }) {
    let updatedAction = counter.actions.updated(value);
    yield put(updatedAction);
}

function* startWatchingCount() {
    let countEventChannel = eventChannel(webstore.WatchCount);

    yield takeEvery(countEventChannel, onUpdateReceived);
}

function* watch() {
    yield all([
        takeLatest(counter.actions.increment, doIncrement)
    ]);
}

export default function* (){
    yield fork(watch);
    yield fork(startWatchingCount);
}