import firebase from 'firebase/app';
require('firebase/database');
require('firebase/auth');

// Initialize Firebase
let config = {
    apiKey: "AIzaSyBA8qlZiR16lbhpwudBC8o_51Gza8cabrM",
    authDomain: "gusdor-flextime.firebaseapp.com",
    databaseURL: "https://gusdor-flextime.firebaseio.com",
    projectId: "gusdor-flextime",
    storageBucket: "gusdor-flextime.appspot.com",
    messagingSenderId: "911571742220"
};

firebase.initializeApp(config);

firebase.auth().useDeviceLanguage();