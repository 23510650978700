import { createSlice } from 'redux-starter-kit';

let slice = createSlice({
    slice: 'counter',
    initialState: { count: '...' },
    reducers: {
        updated: (state, action) => { state.count = action.payload },
        increment: null
    },
})

export default slice;