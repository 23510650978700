import * as webstore from '../store/webstore.js';

const COUNT_PATH = 'counter/count/';

export async function GetCounterValue() {
    return webstore.GetValueFromPath(COUNT_PATH);
}

export async function IncrementCountBy(value) {
    const oldValue = await webstore.GetValueFromPath(COUNT_PATH);
    await webstore.UpdatePath(COUNT_PATH, oldValue + value);

    return value;
}

export function WatchCount(callback) {
    let ret = webstore.WatchPath(COUNT_PATH, callback);
    return ret;
}