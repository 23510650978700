import { createSlice, createSelector } from 'redux-starter-kit';
import _ from 'lodash';
import moment from 'moment';
import Day, { getHoursWorked } from './Domain/Day';
import userSettings from '../UserSettings/UserSettingsSlice';

function setDayValue(state, day, value){
    const path = ['calander', day.year(), day.month(), day.date()];
    return _.set(state, path, value);
}

let slice = createSlice({
    slice: 'flextime',
    initialState: {
        isLoading: true,
        today: moment(),
        calander: {}
     },
    reducers: {
        monthFetched: (state, action) => {
            var { year, month, days } = action.payload;
            _.set(state, ['calander', year, month], days);
        },
        loaded: (state) => ({ ...state, isLoading: false }),
        clockIn: null,
        clockOut: null,
        updateDay: null,
        dayUpdated: (state, action) => {
            const day = action.payload.day;
            
            return setDayValue(state, day, { ...action.payload } );
        },
        updateToday: (state, action) => {
            return { ...state, today: action.payload };
        },
        signedOut: (state) => {
            return { ...state, calander: {} };
        }
    }
})

function pickDay (slice, day) {
    const path = ['calander', day.year(), day.month(), day.date()];
    const result = _.get(slice, path, {});

    return new Day(day, result.in, result.out);
}

slice.selectors.getToday = createSelector(
    [slice.selectors.getFlextime],
    (flextime) => pickDay(flextime, flextime.today)
);

slice.selectors.getThisWeek = createSelector(
    [slice.selectors.getFlextime, slice.selectors.getToday],
    (flextime, { day: today }) => {
        let result = [];
        const currentDay = moment(today).startOf('isoWeek');
        for(let i = 0; i < 7; i++){
            result.push(pickDay(flextime, moment(currentDay)));
            currentDay.add(1, 'days');
        }    
        return result;
    }
);

slice.selectors.getDaysCompletedThisWeek = createSelector(
    [slice.selectors.getThisWeek, slice.selectors.getToday],
    (week, { day: today }) => {
        const isDayCompleted = (x) => x.in && x.out;
        const isTodayOrEarlier = ({day}) => day.isBefore(today, 'day') || day.isSame(today, 'day');

        return week.filter(x => isDayCompleted(x) && isTodayOrEarlier(x)).length;
    }
);

slice.selectors.getHoursWorkedThisWeek = createSelector(
    [slice.selectors.getThisWeek],
    week => {
        return week
        .map(getHoursWorked)
        .filter(p => p)
        .reduce((prev, curr) => (prev || 0) + curr, 0)
    }
);

slice.selectors.getIsLoaded = createSelector(
    [slice.selectors.getFlextime],
    (flextime) => !flextime.isLoading
)

slice.selectors.getFlexAcruedThisWeek = createSelector(
    [
        slice.selectors.getToday,
        slice.selectors.getHoursWorkedThisWeek, 
        slice.selectors.getDaysCompletedThisWeek, 
        userSettings.selectors.getDailyTarget
    ],
    (today, hoursSoFar, daysCompleted, getDailyTarget) => {
        return hoursSoFar - (getDailyTarget(today.day) * daysCompleted);
    });

export default slice;