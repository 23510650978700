import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import Flextime from './Flextime/containers/Layout';
import store from './store/redux.js';
import LoginPortal from './Authentication/containers/LoginPortal.js';
import AuthenticationSwitch from './Authentication/containers/AuthenticationSwitch.js';
import AvTimer from '@material-ui/icons/AvTimer';

// App
const styles = (theme) => ({
  toolbar: theme.mixins.toolbar,
  appBarContent: { // Aligns app bar content to the right
    flexGrow: 1, 
    display:'flex', 
    alignItems:'baseline', 
    justifyContent:'flex-end'
  }
});

class App extends Component {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline/>
          <Provider store={store}>
          <div className="App">
            <header>
              <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"></link>
              <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons"></link>
            </header>
            <AppBar position='fixed' color='primary'>
              <Toolbar>
                <AvTimer fontSize="large" style={{verticalAlign:"middle"}}/>
                <Typography style={{paddingLeft: "5px"}} variant="h6" color="inherit" noWrap>
                  Flextime
                </Typography>
                <div className={classes.appBarContent}>
                  <LoginPortal/>
                </div>
              </Toolbar>
            </AppBar>
            <div className={classes.toolbar} />
            <AuthenticationSwitch whenAuthenticated={<Flextime/>} whenNotAuthenticated={null}/>
          </div>
        </Provider>
        </MuiPickersUtilsProvider>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(App);
