import { createSlice, createSelector } from 'redux-starter-kit';
import SettingsUpdate from './SettingsUpdate'
import moment from 'moment';
import _ from 'lodash';

const settingsUpdateKeyFormat = 'YYYY-MM-DD';

const initialState = {
    default: new SettingsUpdate(42.5, 5),
    updates: []
};

let slice = createSlice({
    slice: 'userSettings',
    initialState,
    reducers: {
        userSettingsUpdated: (state, { payload }) => {
            const updates = payload.updates;
            if(updates){
                return { ...state, updates: updates }
            } else {
                return state;
            }
        },
        signedOut: state => ({ ...state, updates: [] })
    }
})

slice.selectors.getSettingUpdates = state => {
    return createSelector(
        [slice.selectors.getUserSettings],
        settings =>
            Object.keys(settings.updates || [])
            .filter(p => moment(p, settingsUpdateKeyFormat).isValid())
            .sort()
            .reduce((acc, curr) => ({
                ...acc,
                [curr]: settings.updates[curr]
            }), { })
        )(state);
}

slice.selectors.getSettingsForDay = day =>
    createSelector(
        [
            () => day, 
            slice.selectors.getSettingUpdates, 
            slice.selectors.getUserSettings
        ],
        (day, updates, settings) => {
            const matchingUpdate = 
                _.last(Object.keys(updates).sort().filter(p => {
                    return moment(p) <= day
                }));
            const result = _.get(settings, ['updates', matchingUpdate]);
            return result || settings.default;
        });

slice.selectors.getDailyTarget = state => day => {
        const settings = slice.selectors.getSettingsForDay(day)(state);
        return settings.requiredWeeklyHours / settings.workDays;
    }

slice.selectors.getRequiredWeeklyHours = state => day => {
        const settings = slice.selectors.getSettingsForDay(day)(state);
        return settings.requiredWeeklyHours;
    }

export default slice;