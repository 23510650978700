import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';

const style = {
    root: {
        padding: "20px",
        margin: "10px",
        'overflow-x': 'auto'
  }
}

var typeStyle = {
    bottomMargin: "50px"
}

 function PaperWrapper ({title, children, classes}) {
    return <Paper className={classes.root}>
        <div>
        <Typography style={typeStyle} variant='h5'>{title}</Typography>
        {children}
        </div>
    </Paper>
}

export default withStyles(style)(PaperWrapper);