import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getRemainingHoursForWeek } from '../Domain/Day';
import { Typography } from '@material-ui/core';
import userSettings from '../../UserSettings/UserSettingsSlice'
import flextime from '../FlextimeSlice';
import _ from 'lodash';
import { parseTime } from '../Domain/Day';
import moment from 'moment';
import createLogger from '../../Logger';


function formatWithSign(number, decimals){
    const sign = number > 0 ? '+' : '';
    return sign + number.toFixed(decimals);
}

const isDayInProgress = (day) => day.in && !day.out

function getHoursInProgress(day){
    if(isDayInProgress(day)){
        // Get the diff in seconds and convert to hours
        return moment().diff(parseTime(day.in), 'seconds') / 3600;
    } else {
        return 0;
    }
}

const overZero = _.curry(Math.max)(0);

class HoursRemaining extends PureComponent{

    getHoursProgress(){
        return { hoursProgressToday: getHoursInProgress(this.props.today) };
    }

    constructor(props){
        super(props);
        this.log = createLogger(this.constructor.name, false);
        this.state = this.getHoursProgress();
    }

    render(){
        const { requiredWeeklyHours, week, flexAccruedThisWeek, dailyTarget, today } = this.props;
        const remainingHours = 
            overZero(getRemainingHoursForWeek(week, requiredWeeklyHours)) - this.state.hoursProgressToday;

        const flexToday = this.state.hoursProgressToday - dailyTarget;   
        const todayInProgress = isDayInProgress(today)

        const weekComplete = 
            <Typography>
                Week Complete!
            </Typography>;

        const remainingHoursMessage = 
            <Typography>
                <b>{remainingHours.toFixed(2)}</b> hours remaining this week
            </Typography>;
        
        const todaystats = <>Earn <b>{formatWithSign(flexToday, 2)}</b> hours flex if you leave now</>

        return (
            <div>
                {remainingHours <= 0  ? weekComplete : remainingHoursMessage}
                <Typography>
                    <b>{flexAccruedThisWeek.toFixed(2)}</b> hours flex earned
                    <br/>
                    {todayInProgress && todaystats}
                </Typography>
            </div>);
    }


    componentWillMount(){
        this.interval = setInterval(() => {
            const newState = this.getHoursProgress();
            this.log('Setting hours progess', newState.hoursProgressToday);
            this.setState(newState);
        }, 1000);
    }    

    componentWillUnmount(){
        clearInterval(this.interval);
    }
}

function mapStateToProps() {
    const { 
        getFlexAcruedThisWeek, 
        getThisWeek,
        getToday,
    } = flextime.selectors;
    
    const { getRequiredWeeklyHours, getDailyTarget } = userSettings.selectors
    
    return (state) => {
        return {
            flexAccruedThisWeek: getFlexAcruedThisWeek(state),
            requiredWeeklyHours: getRequiredWeeklyHours(state)(getToday(state).day),
            week: getThisWeek(state),
            today: getToday(state),
            dailyTarget: getDailyTarget(state)(getToday(state).day)
        };
    };
}

export default connect(mapStateToProps)(HoursRemaining);