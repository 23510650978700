import { eventChannel } from 'redux-saga';
import { takeEvery, put } from 'redux-saga/effects';
import firebase from 'firebase';
import authentication from './AuthenticationSlice';

var provider = new firebase.auth.GoogleAuthProvider();

function createAuthEventChannel(){
    return eventChannel((callback) =>{
        const cancelFunc = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                callback({ signedIn: true, user });
            } else {
                // No user is signed in.
                callback({ signedIn: false });
            }
        });

        return cancelFunc;
    });
}

function* onAuthStateChanged({ signedIn, user }){
    if(signedIn){
        const payload = { 
            userId: user.uid,
            displayName: user.displayName,
            photoUrl: user.photoUrl
        };
        yield put(authentication.actions.signedIn(payload));
    } else {
        yield put(authentication.actions.signedOut());
    }
}

function* doSignOut(){
    yield firebase.auth().signOut();
}

function* doSignInWithGoogle(){
    yield firebase.auth().signInWithPopup(provider);
}

function* watch(){
    yield takeEvery(authentication.actions.signInWithGoogle, doSignInWithGoogle);
    yield takeEvery(createAuthEventChannel(), onAuthStateChanged);
    yield takeEvery(authentication.actions.signOut, doSignOut);
}

export default watch;